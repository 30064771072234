<template>
  <v-container>
  <v-card>
    <v-card-title>
      Loan Calculator
    </v-card-title>
    <v-card-text>
      <p>Compute payments, interest rates, principal values, payoff terms, and more!</p>
      <br>
      <h3>Inputs</h3>
      <v-text-field label="Principal"></v-text-field>
      <v-text-field label="Term (in Months)"></v-text-field>
      <v-text-field label="Interest Rate"></v-text-field>
      <v-text-field label="Payment"></v-text-field>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn v-on:click="dialog = true">Submit</v-btn>
    </v-card-actions>
  </v-card>
    <nothing-dialog v-model="dialog" v-on:OK="dialog = false"></nothing-dialog>
  </v-container>
</template>

<script>
import NothingDialog from "@/components/NothingDialog";
export default {
  name: "Loan",
  metaInfo: {
    title: 'Loan Tools',
  },
  components: {NothingDialog},
  data: () => ({
    dialog: false,
  })
}
</script>

<style scoped>

</style>
