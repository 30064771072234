<template>
    <v-dialog v-model="value"
              v-on:click:outside="$emit('OK')"
              max-width="500px"
    >
      <v-card >
        <v-card-title>Oops! This is awkward...</v-card-title>
        <v-card-text>
          <p>
            We haven't wired this button up yet!
          </p>
          <p>
            Please check back later!
          </p>
          <p>
            In the meantime, feel free to reach out to <a href="mailto:info@budget.icu">info@budget.icu</a>
          </p>
          <v-card-actions>
            <v-spacer />
            <v-btn
                v-on:click="$emit('OK')"
            >OK</v-btn>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>
</template>

<script>
export default {
  name: "NothingDialog",
  props: {
    value: null,
  }
}
</script>

<style scoped>
</style>
